import axios from "axios";
// const API_ENDPOINT = "http://localhost:3033/api/";
// const IMG_URL = "http://localhost:3033/api/images/"

const API_ENDPOINT = "https://api.vittzios.com/api";
const IMG_URL = "https://api.vittzios.com/api/images/"

const dataService = axios.create({
    baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
    (config) => {
        config.headers.auth = localStorage.getItem("userToken");
        return config;
    },
    (error) => {
        return error;
    }
);

export { dataService, IMG_URL };
