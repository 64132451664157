import React from "react";
import Images from "../../utils/Images";
import { CiLocationOn } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { LuPhone } from "react-icons/lu";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
    <footer>
      <div className="container-fluid">
        <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
        <div className="footer-logo-section footer-logo-content">
            <img className="footer-logo" src={Images.headerLogo} />
            <p
              style={{
                color: "white",
                lineHeight: "21px",
                letterSpacing: "1px",
              }}
            >
              Mindful solutions developed through innovation for your holistic
              care under one umbrella.
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
        <div className="footer-logo-section">
            <p className="footer-logo-section-title">QUICK LINKS </p>
            <div>
              <ul className="footer-list-items">
                <li>
                  <Link className="footer-link" to="/terms-and-conditions">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link className="footer-link" to="/refund-policies">
                    Refund Policies
                  </Link>
                </li>
                <li>
                  <Link className="footer-link" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link className="footer-link" to="/payment-shipping-delivery">
                    Payment Shipping Delivery
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
        <div className="footer-logo-section">
            <div className="footer-right-section">
              <div className="footer-right-section-icon">
                <CiLocationOn />
              </div>
              <p className="footer-right-section-text">
                3rd Floor, Vatika Tower - B, Sector 54, Golf Course Road,
                Gurugram, Haryana - 122003
              </p>
            </div>
            <div className="footer-right-section">
              <div className="footer-right-section-icon">
                <CiMail />
              </div>
              <p className="footer-right-section-text">
                wholesomenutritech@gmail.com
              </p>
            </div>
            <div className="footer-right-section">
              <div className="footer-right-section-icon">
                <LuPhone />
              </div>
                <p className="footer-right-section-text">+91 8200780569</p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;
