import React, { useEffect, useState } from "react";
import Images from "../../utils/Images";
import { dataService, IMG_URL } from "../../config/services";
import { Link } from "react-router-dom";

const MenuBar = () => {
  const [allCategoryList, setAllCategoryList] = useState([]);
  const getAllCategoryList = () => {
    dataService.get("user/category-list").then(({ data }) => {
      setAllCategoryList(data.data);
    });
  };
  useEffect(() => {
    getAllCategoryList();
  }, []);
  return (
    <>
      <div>
        <div className="category">
          {allCategoryList.map((data) => {
            return (
              <div className="category-menu">
                <Link className="navbar-text-items" to={`/product-list?categoryId=${data._id}`}>
                  <img
                    className="menu-items"
                    src={IMG_URL + data.image}
                    alt="no image"
                  />
                </Link>
                <h6>{data?.categoryName}</h6>
              </div>
            );
          })}

          {/* <div className="category-menu">
            <img className="menu-items" src={Images.hairCare} alt="no image" />
            <h6>Hair Care</h6>
          </div>
          <div className="category-menu">
            <img className="menu-items" src={Images.bodyCare} alt="no image" />
            <h6>Body Care</h6>
          </div>
          <div className="category-menu">
            <img className="menu-items" src={Images.wellness} alt="no image" />
            <h6>Wellness</h6>
          </div>
          <div className="category-menu">
            <img className="menu-items" src={Images.fragrance} alt="no image" />
            <h6>Fragrance</h6>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MenuBar;
