import React from "react";
import "../../assets/css/MyProfilePage.css";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import Images from "../../utils/Images";

const MyProfilePage = () => {
  return (
    <div>
      <div className="my-profile-page">My Profile</div>
      <div className="my-profile-personal-information-div">
        <div className="my-profile-page-head">Personal Information:</div>
        <p className="my-profile-personal-information-text">
          Full Name: John Doe
        </p>
        <p className="my-profile-personal-information-text">
          Username: johndoe123
        </p>
        <p className="my-profile-personal-information-text">
          Email: john.doe@example.com
        </p>
        <p className="my-profile-personal-information-text">
          Phone Number: 1234567890
        </p>
      </div>

      <div className="my-profile-billing-address-div">
        <div className="my-profile-page-head">Billing Address:</div>
        <p>Address Line 1: 123 Main St </p>
        <p>Address Line 2: main St </p>
        <p>City: City</p>
        <p>State/Province: State</p>
        <p>Zip/Postal Code: 12345</p>
        <p>Country: Country</p>
      </div>

      <div className="my-profile-billing-address-div">
        <div className="my-profile-page-head">Shipping Address:</div>
        <p>Address Line 1: 123 Main St </p>
        <p>Address Line 2: main St </p>
        <p>City: City</p>
        <p>State/Province: State</p>
        <p>Zip/Postal Code: 12345</p>
        <p>Country: Country</p>
      </div>
      {/* <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-3"></div>
          </div>
        </div>
      </section> */}

      <div className="my-profile-page">My Orders</div>
      <div className="my-orders-main-div">
        <Accordion className="my-orders-order-list" >
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="my-orders-heading">
                Order-Id : <span className="my-orders-heading-in">12345</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="my-orders-details-main">
                <div className="my-orders-details">
                  Order Date :{" "}
                  <span className="my-orders-details-in">2024-02-25</span>{" "}
                </div>
                <div className="my-orders-details">
                  Order Status:{" "}
                  <span className="my-orders-details-in">Delivered</span>
                </div>
              </div>
              <div>
                <div className="table-responsive">
                  <Table className="table">
                    <thead className="table-head-div">
                      <tr>
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-row-div">
                        <td>
                          <div className="cart-product-image-div">
                            <img
                              height="150"
                              width="200"
                              src={Images.product1}
                              alt=""
                              className="cart-product-image"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="cart-product-title">
                            <p>Healthy Hair & Nails Super Gummies</p>
                          </div>
                        </td>
                        <td>
                          <div className="cart-price-text">₹ 500</div>
                        </td>
                        <td>
                          <div className="cart-qty-div">
                            <p className="cart-qty-count">1</p>
                          </div>
                        </td>
                        <td>
                          <div className="cart-price-text">₹500</div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="my-orders-order-list" >
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="my-orders-heading">
                Order-Id : <span className="my-orders-heading-in">67890</span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="my-orders-details-main">
                <div className="my-orders-details">
                  Order Date :{" "}
                  <span className="my-orders-details-in">2024-02-25</span>{" "}
                </div>
                <div className="my-orders-details">
                  Order Status:{" "}
                  <span className="my-orders-details-in">Delivered</span>
                </div>
              </div>
              <div>
                <div className="table-responsive">
                  <Table className="table">
                    <thead className="table-head-div">
                      <tr>
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-row-div">
                        <td>
                          <div className="cart-product-image-div">
                            <img
                              height="150"
                              width="200"
                              src={Images.product1}
                              alt=""
                              className="cart-product-image"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="cart-product-title">
                            <p>Healthy Hair & Nails Super Gummies</p>
                          </div>
                        </td>
                        <td>
                          <div className="cart-price-text">₹ 500</div>
                        </td>
                        <td>
                          <div className="cart-qty-div">
                            <p className="cart-qty-count">1</p>
                          </div>
                        </td>
                        <td>
                          <div className="cart-price-text">₹500</div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default MyProfilePage;
