import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCartData, productManagement } from "./service";

export const Slice = createSlice({
    name: "store",
    initialState: {
        token: "",
        cart: []
    },
    reducers: {
        logout: (state) => {
            state.token = "";
        },
        logIn: (state, { payload }) => {
            state.token = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(productManagement.fulfilled, (state, { payload }) => {
            state.cart = payload;
        });
        builder.addCase(fetchCartData.fulfilled, (state, { payload }) => {
            state.cart = payload;
        })
    }
});
export const {
    logout,
    logIn
} = Slice.actions;
export default Slice.reducer;
