import React from "react";
import "../../assets/css/signUp.css";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { SignUpSchema } from "../../validation/schema";
import { dataService } from "../../config/services";

const SignUp = () => {
  const navigate = useNavigate();
  const submitForm = (data) => {
    console.log(data)
    dataService.post('user/sign-up', data).then(({data})=>{
      navigate("/login");
    }).catch((e)=>{
      console.log(e)
    })
  }
  return (
    <div className="sign-up-main-div">
      <div className="sign-up-box-div">
        <p className="sign-up-text"> Sign Up </p>
        <Formik
          validationSchema={SignUpSchema}
          initialValues={{
            email: "",
            password: "",
            lastName:"",
            firstName:""
          }}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isValid,
            dirty,
            touched,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* <div className="sign-up-text-fields"> */}
              <div class="sign-up-text-field-div">
                <label
                  className="form-lable"
                  for="exampleFormControlInput1"
                  class="form-label"
                >
                  First Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="firstName"
                />
              </div>
              <div class="sign-up-text-field-div">
                <label
                  className="form-lable"
                  for="exampleFormControlInput1"
                  class="form-label"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="lastName"
                />
              </div>
              {/* </div> */}
              {/* <div className="sign-up-text-fields"> */}
              <div class="sign-up-text-field-div">
                <label
                  className="form-lable"
                  for="exampleFormControlInput1"
                  class="form-label"
                >
                  Email
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                />
              </div>
              {/* </div> */}
              {/* <div className="sign-up-text-fields"> */}
              <div class="sign-up-text-field-div">
                <label
                  className="form-lable"
                  for="exampleFormControlInput1"
                  class="form-label"
                >
                  Password
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="password"
                />
              </div>
              {/* </div> */}
              <Button className="sign-up-button" type="submit" variant="primary">
                Sign Up
              </Button>
            </form>
          )}
        </Formik>
        <div className="signup-have-account-section">
          <p className="signup-have-account-text"> Already have an account? </p>
          <Link to={"/login"} className="signup-login-text">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
