import React, { useEffect, useState } from "react";
import "../../assets/css/CheckOutPage.css";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, useFormik } from "formik";
import { checkOutSchema, checkOutSchemaWithS } from "../../validation/schema";
import { dataService } from "../../config/services";
import { useSelector } from "react-redux";

const CheckOutPage = () => {

  const [values, setValues] = useState({});
  const [checked, setChecked] = useState(false);
  console.log(values, "maulik12");
  const changeValue = {
    firstName: "sFirstName",
    lastName: "sLastName",
    address: "sAddress",
    pincode: "sPincode",
    city: "sCity",
    state: "sState",
    country: "sCountry",
    email: "sEmail",
    phone: "sPhone",
  }
  const initialAddress = {
    firstName: "",
    lastName: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    email: "",
    phone: "",
  };
  const initialAddressS = {
    sFirstName: "",
    sLastName: "",
    sAddress: "",
    sCity: "",
    sPincode: "",
    sState: "",
    sCountry: "",
    sEmail: "",
    sPhone: "",
  };
  const [initialValues, setInitialValues] = useState({
    ...initialAddress,
    ...initialAddressS
  });
  console.log(initialValues, "maulik39")
  const { state } = useLocation();
  console.log(state.couponCode, "maulik12");
  const [cartDetails, setCartDetails] = useState({
    totalAmount: 0,
    discount: 0,
  });
  const submitForm = (data) => {
    console.log(data, "maulik33");
    dataService
      .post("cart/shipment", { address: { ...data }, promoCode: state.couponCode })
      .then(({ data }) => {
        window.location.replace(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema: !checked ? checkOutSchemaWithS : checkOutSchema,
    onSubmit: (values) => {
      submitForm(values)
    }
  })

  const { cart } = useSelector((state) => state.user);



  const CheckOut = () => {
    dataService
      .get(`cart/check-out?promoCode=${state.couponCode}`)
      .then(({ data }) => {
        setCartDetails((e) => ({ ...e, totalAmount: data.data.totalAmount }));
        console.log(data, "maulik25");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    CheckOut();
  }, []);

  useEffect(() => {
    if (checked) {
      for (const key in changeValue) {
        formik.setFieldValue(changeValue[key], formik.values[key])
      }
    }
  }, [checked]);

  return (
    <>
      <div>
        <p className="check-out-page-title">Checkout</p>
        <div className="check-out-page-price">
          <div>Total Amount</div>
          <div>₹ {cartDetails?.totalAmount}</div>
        </div>
        <div className="check-out-page-address-main-div">
          <div className="check-out-page-billing-div">
            <p className="check-out-page-billing-title">Billing Details</p>
            <div className="check-out-page-billing">
              <div>Billing Address Use As Shipping Address</div>
              <div className="check-out-page-billing-check-button">
                <input
                  id="toggle-check"
                  type="checkbox"
                  variant="secondary"
                  checked={checked}
                  value="1"
                  onChange={(e) => setChecked(e.target.checked)}
                />
              </div>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="firstName"
                  />
                </div>
                <div className="check-out-page-text-field-div">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="lastName"
                  />
                </div>
              </div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="address"
                  />
                </div>
              </div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="pincode" className="form-label">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pincode"
                    value={formik.values.pincode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="pincode"
                  />
                </div>
                <div className="check-out-page-text-field-div">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="state"
                  />
                </div>
                <div className="check-out-page-text-field-div">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="city"
                  />
                </div>
              </div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="country"
                  />
                </div>
                <div className="check-out-page-text-field-div">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="email"
                  />
                </div>
              </div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="phone"
                  />
                </div>
              </div>
            </div>
            <div className="check-out-page-billing-div">
              <p className="check-out-page-billing-title">Shipping Details</p>
            </div>
            <div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sFirstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sFirstName"
                    value={formik.values.sFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sFirstName"
                  />
                </div>
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sLastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sLastName"
                    value={formik.values.sLastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sLastName"
                  />
                </div>
              </div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sAddress" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sAddress"
                    value={formik.values.sAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sAddress"
                  />
                </div>
              </div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sPincode" className="form-label">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sPincode"
                    value={formik.values.sPincode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sPincode"
                  />
                </div>
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sState" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sState"
                    value={formik.values.sState}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sState"
                  />
                </div>
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sCity" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sCity"
                    value={formik.values.sCity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sCity"
                  />
                </div>
              </div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sCountry" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sCountry"
                    value={formik.values.sCountry}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sCountry"
                  />
                </div>
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sEmail" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sEmail"
                    value={formik.values.sEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sEmail"
                  />
                </div>
              </div>
              <div className="check-out-page-text-field-row">
                <div className="check-out-page-text-field-div">
                  <label htmlFor="sPhone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="sPhone"
                    value={formik.values.sPhone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="sPhone"
                  />
                </div>
              </div>
            </div>
            <div>
              <button type="submit" className="paybtn">
                Process to pay
              </button>
            </div>
          </form>


        </div>
      </div>
    </>
  );
};

export default CheckOutPage;
