import React from "react";

const RefundPolicies = () => {
  return (
    <div className="main-div">
      <h1>Order Cancellation, Refund, and Return Policy</h1>

      <section>
        <p>
          At Vittzios, we understand that circumstances may arise that require
          you to cancel your order. We have designed a straightforward order
          cancellation process to ensure your convenience. Please refer to the
          following guidelines to cancel your order for Health Gummies:
        </p>

        <h2>Cancellation Process:</h2>
        <p>
          You can initiate the order cancellation process by following these
          steps:
        </p>

        <h2>Online Platform:</h2>
        <p>
          Visit our official website and navigate to the "My Orders" page within
          your account. Here, you will find a list of your orders, and against
          each applicable order, a cancellation button will be displayed.
        </p>

        <h2>Cancellation Timing:</h2>
        <p>
          You have the flexibility to request the cancellation of your order at
          any time prior to the scheduled delivery.
        </p>

        <h2>Cancellation Procedure:</h2>
        <p>
          Locate the order you wish to cancel and click on the provided
          cancellation button.
        </p>
        <p>
          For orders that have not yet been delivered, the cancellation process
          will be seamless, and no further action is required from your end.
        </p>

        <h2>Refund Process:</h2>
        <p>
          For prepaid orders, the refund process varies based on the status of
          your order:
        </p>
        <p>
          If the order is in the "Confirmed" status, you can opt for either of
          the following refund methods:
        </p>
        <ul>
          <li>
            Refund to your original payment source, which might take 5-7 days to
            reflect.
          </li>
        </ul>
        <p>
          For orders that have not yet been delivered (statuses prior to
          delivery), the refund will be processed directly to your original
          payment source.
        </p>
        <p>
          In the event that your order has been shipped (identified by statuses
          such as 'Shipped' or 'In-transit'), cancelling the order will trigger
          a notification to our logistics partner. The return process will be
          initiated within 24 hours of cancellation. Refunds will be initiated
          only upon the confirmation of the return process.
        </p>

        <p>
          We appreciate your understanding of our order cancellation policy for
          Health Gummies. Should you have any questions or require assistance,
          our customer support team is available to guide you through the
          process. Thank you for choosing Vittzios for your health and wellness
          needs.
        </p>
      </section>

      <section class="">
        <h2>Cancellation and Refund Status Communication:</h2>
        <p>
          Upon successful cancellation of your order, you will receive a
          notification via WhatsApp/SMS confirming the cancellation.
        </p>
        <p>
          When a refund reference ID is generated by our payment partner, you
          will be notified via WhatsApp/SMS. Refunds are typically credited to
          your original payment source within 5-7 days after this notification.
        </p>
      </section>

      <section class="">
        <h2>Returns and Replacements:</h2>
        <p>Our policy does not allow for general returns.</p>
        <p>We prioritize the quality and integrity of our products.</p>
        <p>
          However, in the following exceptional circumstances, we are committed
          to facilitating replacements if you inform us within 7 days of
          receiving the order:
        </p>
        <ul>
          <li>
            <span class="">Incorrect products delivered</span>
          </li>
          <li>
            <span class="">Missing products in the order</span>
          </li>
          <li>
            <span class="">Receipt of damaged products</span>
          </li>
          <li>
            <span class="">Delivery of expired products</span>
          </li>
        </ul>

        <p>Conditions for Non-Acceptance of Returns or Exchanges:</p>
        <p>
          However, there are certain conditions under which we will not be able
          to accept returns or process exchanges:
        </p>
        <ul>
          <li>Opened, used, or altered products.</li>
          <li>
            Missing original packaging elements such as mono cartons, labels,
            etc.
          </li>
          <li>
            Return or replacement requests generated three days after product
            delivery.
          </li>
          <li>
            Reporting damaged or missing products two days after product
            delivery
          </li>
        </ul>

        <p>
          To request a return/replacement choose either of the following
          methods:
        </p>
        <ul>
          <li>
            Within 7 days of receiving the product, place a return/exchange
            request.
          </li>
          <li>
            Drop an email to{" "}
            <a href="mailto:wholesomenutritech@gmail.com">
              wholesomenutritech@gmail.com
            </a>
            , including the Order ID, registered mobile number, and reason for
            return/exchange.
          </li>
        </ul>

        <p>To Initiate return/replacement request please follow the steps:</p>
        <ul>
          <li>
            Provide Video or Photo Proof: For any issues, kindly share a video
            or photo proof to help us assess the product's quality and expedite
            your request.
          </li>
          <li>Allow two working days for us to review your return request.</li>
          <li>
            Pickup Process: Once your return request is approved, we will
            arrange for our courier partner to pick up the delivered products.
          </li>
          <li>
            Verification and Replacement/Refund Initiation: Upon receiving your
            returned product(s), we will verify it against the claim and proceed
            with either replacement or refund based on stock availability.
          </li>
          <li>
            Refund Processing: Once your return is successfully processed, your
            refund will be initiated. Refunds should reflect in your original
            mode of payment within 6-7 working days.
          </li>
        </ul>

        <p>
          Our customer happiness team may require additional information before
          processing your replacement request. Once approved, a new order ID for
          the replacement will be generated and can be viewed in your order
          history.
        </p>
      </section>

      <section>
        <h2>Miscellaneous:</h2>
        <p>
          In rare cases of payment partner network failures leading to the
          absence of a refund reference ID, please reach out to us on{" "}
          <a href="mailto:wholesomenutritech@gmail.com">
            wholesomenutritech@gmail.com
          </a>
          . We will diligently investigate the matter, and if the circumstances
          are valid, we will provide a separate link within 24 hours for you to
          claim the refund.
        </p>
      </section>

      <section class="rules-section">
        <h2>Contact Information:</h2>
        <p>
          For any inquiries or clarifications regarding our Cancellation,
          Refund, and Return Policy, please don't hesitate to reach out to us on{" "}
          <a href="mailto:wholesomenutritech@gmail.com">
            wholesomenutritech@gmail.com
          </a>
          .
        </p>
        <p>
          Thank you for choosing us for your health gummies needs, and we are
          here to ensure a seamless shopping experience for you.
        </p>
      </section>
    </div>
  );
};

export default RefundPolicies;
