import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "../src/screens/home/Home";
import { Route, Routes, Outlet } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import SingleProductList from "./screens/skinCare/SingleProductList";
import SingleProductPage from "./screens/SingleProductPage/SingleProductPage";
import Cart from "./screens/cart/Cart";
import CheckOutPage from "./screens/CheckOut/CheckOutPage";
import Login from "./components/login/Login";
import SignUp from "./components/signUp/SignUp";
import TermsAndConditions from "./screens/termsAndConditions/TermsAndConditions";
import RefundPolicies from "./screens/refundPolicies/RefundPolicies";
import PrivacyPolicy from "./screens/privacyPolicy/PrivacyPolicy";
import PaymentShippingDelivery from "./screens/paymentShippingDelivery/PaymentShippingDelivery";
import MyProfilePage from "./screens/myProfile/MyProfilePage";

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

function App() {
  return (
    <>
      {/*  */}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/product-list" element={<SingleProductList />} />
          <Route path="/single-prduct" element={<SingleProductPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/check-out" element={<CheckOutPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/refund-policies" element={<RefundPolicies />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/payment-shipping-delivery"
            element={<PaymentShippingDelivery />}
          />
          <Route path="/my-profile" element={<MyProfilePage />} />
        </Route>
      </Routes>
      {/*  */}
    </>
  );
}

export default App;
