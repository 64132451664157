import React from "react";
import Images from "../../utils/Images";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
// import products from "../home/productData";
import { addProduct } from "../../redux/slices/slices";
import { useDispatch, useSelector } from "react-redux";
import { IMG_URL } from "../../config/services";
import { Link } from "react-router-dom";
import { productManagement } from "../../redux/slices/service";
import Slider from "react-slick";

var settings = {
  dots: true,
  arrow: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
};

const ProductCategoryList = ({ products, name, banner, categoryId }) => {
  const dispatch = useDispatch();
  return (
    <div className="product-category-main-container">
      <div>
        <img className="product-category-image-banner" src={banner} alt="" />
        <img
          className="product-category-image-banner-responsive"
          src={banner}
          alt=""
        />
      </div>
      <div className="product-category-title-section">
        <div className="product-category-button">
          <Link
            className="navbar-text-items"
            to={`/product-list?categoryId=${categoryId}`}
          >
            <Button
              className="product-category-viewall-button"
              variant="primary"
            >
              View All
            </Button>
          </Link>
        </div>
        <p className="product-category-title">{name}</p>
      </div>
      <div>
        <div className="product-category-card-main">
          {products?.map((item) => {
            return (
              <div className="product-category-card-div">
                <Card className="common-cards" style={{ width: "18rem" }}>
                  <h6 className="top-title">New Launche</h6>
                  <Link to={`/single-prduct?productId=${item._id}`}>
                    <Card.Img variant="top" src={IMG_URL + item.mainImage} />
                    <Card.Title className="m-0">{item.name}</Card.Title>
                  </Link>
                  <Card.Body className="d-flex gap-3 flex-column">
                    <Card.Text>₹ {item.price}</Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      <span className="highlight-text">Use for</span>{" "}
                      {item.useFor}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <span className="highlight-text">Ingredients</span>{" "}
                      {item.contains}
                    </ListGroup.Item>
                  </ListGroup>
                  <Card.Body className="product-btn-div">
                    <Button
                      className="add-to-cart-product-btn"
                      variant="primary"
                      onClick={() =>
                        dispatch(
                          productManagement({ productId: item._id, add: true })
                        )
                      }
                    >
                      Add To Cart
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>

      {/* <div className="product-category-card-main">
        <div className="container-fluid text-center">
          <Slider {...settings}>
            <div>
              {products?.map((item) => {
                return (
                  <div className="product-category-card-div">
                    <Card className="common-cards" style={{ width: "18rem" }}>
                      <h6 className="top-title">New Launche</h6>
                      <Link to={`/single-prduct?productId=${item._id}`}>
                        <Card.Img
                          variant="top"
                          src={IMG_URL + item.mainImage}
                        />
                        <Card.Title className="m-0">{item.name}</Card.Title>
                      </Link>
                      <Card.Body className="d-flex gap-3 flex-column">
                        <Card.Text>₹ {item.price}</Card.Text>
                      </Card.Body>
                      <ListGroup className="list-group-flush">
                        <ListGroup.Item>
                          <span className="highlight-text">Use for</span>{" "}
                          {item.useFor}
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span className="highlight-text">Ingredients</span>{" "}
                          {item.contains}
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Body className="product-btn-div">
                        <Button
                          className="add-to-cart-product-btn"
                          variant="primary"
                          onClick={() =>
                            dispatch(
                              productManagement({
                                productId: item._id,
                                add: true,
                              })
                            )
                          }
                        >
                          Add To Cart
                        </Button>
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}
            </div>
          </Slider>
        </div>
      </div> */}
    </div>
  );
};

export default ProductCategoryList;
