import React from "react";

const PaymentShippingDelivery = () => {
  return (
    <div className="main-div">
      <h1>Payment Policy</h1>

      <section>
        <p>
          At Vittzios, we facilitate secure payment options to enhance your
          shopping experience. We currently accept payments through leading
          credit and debit cards such as Visa and Mastercard, as well as UPI,
          RuPay, PayTM, and the convenient Cash on Delivery (COD) method.
        </p>

        <p>
          In case you notice a deduction from your account but haven't received
          confirmation from us, our dedicated customer care team is here to
          assist. To expedite resolution, kindly provide the following details:
        </p>

        <ul>
          <li>
            <span class="">Transaction Reference Number</span>
          </li>
          <li>
            <span class="">Debited Amount</span> along with your{" "}
            <span class="highlight">Registered Email ID</span>
          </li>
          <li>
            <span class="">Date and Time of the Transaction</span>
          </li>
          <li>
            <span class="">Mobile Number</span>
          </li>
          <li>
            <span class="">Order Screenshot</span>
          </li>
        </ul>

        <p>
          Upon receiving this information, we will promptly verify the details
          and respond accordingly.
        </p>

        <p>
          Your trust in Vittzios is paramount, and our commitment to secure
          payment processing ensures your peace of mind during every
          transaction. For any payment-related queries, don't hesitate to get in
          touch with our customer care team using the contact details provided
          below.
        </p>
      </section>

      <section class="contact-info">
        <h2>Contact Information for Payment-related Inquiries</h2>
        <p>
          Email:{" "}
          <a href="mailto:wholesomenutritech@gmail.com">
            wholesomenutritech@gmail.com
          </a>
        </p>
      </section>

      <h1>Shipping Policy</h1>

      <section>
        <p>
          At Vittzios, we strive to provide a seamless and convenient shopping
          experience, including a transparent shipping process. Please review
          the following shipping details for a clear understanding:
        </p>

        <h2>Shipping Charges:</h2>
        <ul>
          <li>
            For prepaid orders, we are pleased to offer free shipping on all
            orders placed with Clensta.
          </li>
          <li>
            For Cash on Delivery (COD) orders, a nominal delivery charge of ₹30
            is applicable.
          </li>
        </ul>

        <h2>Dispatch and Notifications:</h2>
        <ul>
          <li>
            Upon dispatch of your order, you will receive an SMS and email
            notification containing the tracking number and courier{" "}
            <strong>Company</strong> details.
          </li>
          <li>
            These details will be sent to your registered contact information.
          </li>
        </ul>

        <h2>Order Processing Time:</h2>
        <p>
          Orders are generally processed and shipped within 2-4 working days.
          Please note that during peak Sale periods, dispatch and delivery may
          experience delays due to the higher volume of orders. Despite this,
          our goal is to dispatch all orders within seven working days.
        </p>

        <h2>Delivery Time:</h2>
        <p>
          The delivery time for your product may vary based on your location.
          Factors such as your state of residence and logistical considerations
          can influence delivery times.{" "}
        </p>

        <p>
          Your satisfaction is our priority, and we are committed to ensuring a
          smooth and timely delivery process. Should you have any queries or
          require assistance, please don't hesitate to contact our customer
          support team using the details provided below.
        </p>
      </section>

      <section class="contact-info">
        <h2>Contact Information for Shipping-related Queries</h2>
        <ul>
          <li>
            Email:{" "}
            <a href="mailto:wholesomenutritech@gmail.com">
              wholesomenutritech@gmail.com
            </a>
          </li>
        </ul>
      </section>

      <h1>Delivery Policy</h1>

      <section>
        <p>
          At Vittzios, we are dedicated to ensuring a seamless and secure
          delivery experience for our valued customers. Kindly review the
          following details to familiarize yourself with our delivery policy:
        </p>

        <h2>Cash on Delivery (COD) Availability:</h2>
        <p>
          Please note that the Cash on Delivery (COD) option is only available
          for specific Pin Codes. Prior to placing a COD order, we kindly
          request you to verify whether your region is eligible for this payment
          method.
        </p>

        <h2>Product Safety and Packaging:</h2>
        <p>
          Your satisfaction and product integrity are our utmost priorities.
          Rest assured, we take extensive measures to ensure the safety of your
          product during shipping. In the event that your shipment appears
          tampered with or damaged upon delivery, we kindly ask you not to
          accept it. Instead, please notify us promptly.
        </p>

        <h2>Expiry Date Criteria for Returns:</h2>
        <p>
          Products with an expiration date of less than three months are
          eligible for returns. In such cases, the full product amount will be
          credited to the concerned person as per our return policy. However,
          please note that products with an expiration date exceeding three
          months are not eligible for returns or refund requests.
        </p>

        <p>
          We value your trust and aim to provide a transparent and reliable
          delivery process. If you have any questions or require further
          assistance, our customer support team is readily available to help.
          You can reach out to us using the contact information provided below.
        </p>
      </section>

      <section class="contact-info">
        <h2>Contact Information for Delivery-related Queries</h2>
        <ul>
          <li>
            Email:{" "}
            <a href="mailto:wholesomenutritech@gmail.com">
              wholesomenutritech@gmail.com
            </a>
          </li>
        </ul>
        <p>
          For any inquiries or clarifications regarding our Cancellation,
          Refund, and Return Policy, please don't hesitate to reach out to us on{" "}
          <a href="mailto:wholesomenutritech@gmail.com">
            wholesomenutritech@gmail.com
          </a>
          .
        </p>
        <p>
          Thank you for choosing us for your health gummies needs, and we are
          here to ensure a seamless shopping experience for you.
        </p>
      </section>
    </div>
  );
};

export default PaymentShippingDelivery;
