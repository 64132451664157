import React, { useEffect, useState } from "react";
import Images from "../../utils/Images";
import Button from "react-bootstrap/Button";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { useSearchParams } from "react-router-dom";
import { dataService, IMG_URL } from "../../config/services";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { addProduct } from "../../redux/slices/slices";
import { useDispatch } from "react-redux";
import { productManagement } from "../../redux/slices/service";

const SingleProductPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");


  const [productDetails, setProductDetails] = useState({});
  const { otherImage, price, mainImage, bannerImage, topImage, ingredients, name } = productDetails;
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img height="80" width="80" src={IMG_URL + otherImage[i]?.file} />
        </a>
      );
    },
    dots: true,
    arrows: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const getProductDetails = () => {
    dataService
      .get(`user/single-product?productId=${productId}`)
      .then(({ data }) => {
        setProductDetails({ ...data.data, otherImage: [{ file: data.data.mainImage }, ...data.data.otherImage] });
      });
  };
  useEffect(() => {
    getProductDetails();
  }, [productId]);

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="single-product-main-div">
            <div className="single-product-image-main">
              <img
                className="single-product-image"
                src={IMG_URL + topImage}
                alt=""
              />
            </div>
          </div>
          <div className="mt-4">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Slider {...settings}>
                  {
                    otherImage?.map((e) => {
                      return (
                        <div>
                          <img height="500" width="450" src={IMG_URL + e.file} />
                        </div>
                      )
                    })
                  }

                </Slider>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="single-product-price-card-section">
                  {/* <div className="single-product-price-card-div">
                    <div className="single-product-price-card">
                      <h4 style={{ margin: 0, textAlign: "center" }}>
                        60 days Pack Rs-1299
                      </h4>
                    </div>
                    <div className="single-product-price-card">
                      <h4>90 days Pack Rs-1699</h4>
                    </div>
                  </div> */}
                  <div className="product-prise">{name}</div>
                  <div className="product-prise">₹ {price}</div>
                  {/* <div className="product-prise-time">1 Month | 30 GUMMISE</div> */}
                  <div className="product-btns">
                    <Button variant="primary" className="single-product-button">
                      Buy Now
                    </Button>
                    <Button onClick={() => dispatch(productManagement({ productId: productDetails?._id, add: true }))} variant="primary" className="single-product-button">
                      Add To Cart
                    </Button>
                  </div>
                  <div className="single-product-discription-main">
                    <div className="product-description-title">Ingredients</div>
                    <div>
                      <div className="product-des-div">

                        <p className="product-discription">
                          <span className="product-discription-span">
                            {" "}
                            {/* BIOTIN:{" "} */}
                          </span>{" "}
                          {ingredients}
                        </p>
                      </div>
                      {/* <div className="product-des-div">

                        <p className="product-discription">
                          <span className="product-discription-span">
                            {" "}
                            VITAMIN B6 :{" "}
                          </span>{" "}
                          It's involved the production of melanin,which give
                          hair natural color. vitamin B6 regulating oil of scalp
                          so it's prevent dandruf & dryscalp.
                        </p>
                      </div>
                      <div className="product-des-div">

                        <p className="product-discription">
                          <span className="product-discription-span">
                            {" "}
                            ROSEMARY EXTRACT :{" "}
                          </span>{" "}
                          It contains antioxidant that strengthen hair stand &
                          prevent breakage, its antibacterial & antifungal
                          properties control dandruf, promote strong &
                          healthynails.
                        </p>
                      </div>
                      <div className="product-des-div">

                        <p className="product-discription">
                          <span className="product-discription-span">
                            {" "}
                            ZINC :{" "}
                          </span>{" "}
                          it's involved in DNA & protein synthesis, which are
                          essential for hair cell production.
                        </p>
                      </div>
                      <div className="product-des-div">

                        <p className="product-discription">
                          <span className="product-discription-span">
                            {" "}
                            SELENIUM :{" "}
                          </span>{" "}
                          It's antioxidant properties helps protect hair
                          follicles from damage caused it's maintains hair
                          elasticity.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row text-center my-5">
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <img className="" src={Images.nonHabitForming} alt="Img" />
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <img className="" src={Images.nonHabitForming} alt="Img" />
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <img className="" src={Images.nonHabitForming} alt="Img" />
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <img className="" src={Images.nonHabitForming} alt="Img" />
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <img className="" src={Images.nonHabitForming} alt="Img" />
              </div>
              <div className="col-6 col-sm-6 col-md-4 col-lg-2">
                <img className="" src={Images.nonHabitForming} alt="Img" />
              </div>

            </div>

            <div className="row">
              <div className="col-12 col-sm-12-col-6 col-lg-6">
                <div className="product-main-img">
                  <img className="img-fluid" src={Images.useThisFor} alt="" />
                </div>
              </div>
              <div className="col-12 col-sm-12-col-6 col-lg-6">
                <div className="product-main-img mt-4 mt-sm-4 mt-md-4 mt-lg-0">
                  <img className="img-fluid" src={Images.howToUse} alt="" />
                </div>
              </div>
            </div> */}
            {
              bannerImage?.map((e) => {
                return (
                  <div className="my-5">
                    <img
                      className="product-category-image-banner"
                      src={IMG_URL + e.file}
                      alt=""
                    />
                    <img
                      className="product-category-image-banner-responsive"
                      src={IMG_URL + e.file}
                      alt=""
                    />
                  </div>
                )
              })
            }

          </div>
        </div>
      </section>

      {/* <div className="single-product-icon-section-main">
        <div className="single-product-icon-section">
          <img className="" src={Images.delicious} alt="" />
          <img className="" src={Images.hundredPrVegan} alt="" />
          <img className="" src={Images.gelatinFree} alt="" />
          <img className="" src={Images.sugarFree} alt="" />
          <img className="" src={Images.gmoFree} alt="" />
        </div>
      </div> */}
    </>
  );
};

export default SingleProductPage;
