import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { dataService } from "../../config/services";

export const productManagement = createAsyncThunk("user/cartManagement", async (payload) => {
    try {
        const { data } = await dataService.post('cart/add-to-cart', payload);
        return data?.data;
    } catch (error) {
        console.log(error)
    }
});

export const fetchCartData = createAsyncThunk("user/getCart", async () => {
    try {
        const { data } = await dataService.get('cart/get-cart');
        return data.data;
    } catch (error) {
        console.log(error)
    }
});