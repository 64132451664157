import React, { useEffect, useState } from "react";
import MenuBar from "../../components/home/MenuBar";
import Slider from "../../components/home/Slider";
import ProductCategoryList from "../../components/home/SkinCareProductCategory";
// import HairCareProductCategory from "../../components/home/HairCareProductCategory";
// import WellnessProductCategory from "../../components/home/WellnessProductCategory";
// import BodyCareProductCategory from "../../components/home/BodyCareProductCategory";
// import FragranceProductCategory from "../../components/home/FragranceProductCategory";
import { dataService, IMG_URL } from "../../config/services";

const Home = () => {
  const [allCategoryListProduct, setAllCategoryListProduct] = useState([]);
  const getAllCategoryListProducts = () => {
    dataService.get("user/home-page-products").then(({ data }) => {
      setAllCategoryListProduct(data.data);
    });
  };
  useEffect(() => {
    getAllCategoryListProducts();
  }, []);
  return (
    <div>
      {/*  */}
      <Slider />
      <MenuBar />
      {allCategoryListProduct.map((e) => {
        return e?.data?.length ? (
          <ProductCategoryList
            products={e.data}
            name={e.name}
            banner={e.banner && IMG_URL + e.banner.image}
            categoryId={e.categoryId}
          />
        ) : (
          ""
        );
      })}
      {/* <HairCareProductCategory /> */}
      {/* <WellnessProductCategory />
      <BodyCareProductCategory />
      <FragranceProductCategory /> */}
      {/*  */}
    </div>
  );
};

export default Home;
