import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Images from "../../utils/Images";
import { IoLogIn } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { dataService } from "../../config/services";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartData } from "../../redux/slices/service";

const Header = () => {
  const dispatch = useDispatch();
  const { cart, token } = useSelector((state) => state?.user);
    const navigate = useNavigate();

  const [allCategoryList, setAllCategoryList] = useState([]);
  const getAllCategories = () => {
    dataService.get("user/category-list").then(({ data }) => {
      setAllCategoryList(data.data);
    });
  };
  useEffect(() => {
    getAllCategories();
    dispatch(fetchCartData());
  }, []);
  return (
    <header>
      <Navbar expand="lg" className="header-main-container">
        <Container className="header-container">
          <div>
            <Link to="/">
              <img
                className="header-image-logo"
                src={Images.headerLogo}
                alt=""
              />
            </Link>
          </div>
          <div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto navbar-menu-item">
                {allCategoryList.map((e) => {
                  return (
                    <Link
                      className="navbar-text-items"
                      to={`/product-list?categoryId=${e._id}`}
                    >
                      {e.categoryName}
                    </Link>
                  );
                })}
                {/* <Link className="navbar-text-items" to="/product-list">Effervescent tablet</Link>
                <Link className="navbar-text-items" to="/product-list">Ayurvedic tablet</Link>
                <Link className="navbar-text-items" to="/product-list">Ayurvedic juices</Link>
                <Link className="navbar-text-items" to="/product-list">Skin care</Link>
                <Link className="navbar-text-items" to="/">Hair care</Link> */}
              </Nav>
            </Navbar.Collapse>
          </div>
          <div className="header-right-section">
            {token ? (
              <div
                onClick={() => {
                  navigate("/my-profile");
                }}
                className="navbar-icon"
              >
                <FaRegUser />
              </div>
            ) : (
              <div
                onClick={() => {
                  navigate("/login");
                }}
                className="navbar-icon"
              >
                Login
              </div>
            )}

            <div
              className="navbar-icon"
              onClick={() => {
                navigate("/cart");
              }}
            >
              <MdOutlineShoppingCart />
              <sup>{cart?.products?.length || 0}</sup>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
