import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { dataService, IMG_URL } from "../../config/services";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { productManagement } from "../../redux/slices/service";

const SingleProductList = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");
  const [{ allCategory, banners }, setAllProductByCategory] = useState([]);
  const getAllProductByCategory = () => {
    dataService
      .get(`user/product-list?categoryId=${categoryId}`)
      .then(({ data }) => {
        setAllProductByCategory(data.data);
      });
  };
  useEffect(() => {
    if (categoryId) {
      getAllProductByCategory();
    }
  }, [categoryId]);

  return (
    <>
      <div>
        <img
          className="product-category-image-banner"
          src={IMG_URL + banners?.image}
          alt=""
        />
        <img
          className="product-category-image-banner-responsive"
          src={IMG_URL + banners?.image}
          alt=""
        />
      </div>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
              <div className="product-category-listing-div">
                {allCategory &&
                  allCategory?.map((item) => {
                    return (
                      <div>
                        <div className="product-category-card-main">
                          <Card className="common-cards">
                            <h6 className="top-title">New Launche</h6>
                            <Link to={`/single-prduct?productId=${item._id}`}>
                              <Card.Img
                                variant="top"
                                src={IMG_URL + item.mainImage}
                              />
                              <Card.Title className="m-0">
                                {item.name}
                              </Card.Title>
                            </Link>
                            <Card.Body className="d-flex gap-3 flex-column">
                              <Card.Text>₹ {item.price}</Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                              <ListGroup.Item>
                                <span className="highlight-text">Use for</span>{" "}
                                {item.useFor}
                              </ListGroup.Item>
                              <ListGroup.Item>
                                <span className="highlight-text">
                                  Ingredients
                                </span>{" "}
                                {item.contains}
                              </ListGroup.Item>
                            </ListGroup>
                            <Card.Body className="product-btn-div">
                              <Button
                                className="add-to-cart-product-btn"
                                variant="primary"
                                onClick={() =>
                                  dispatch(
                                    productManagement({
                                      productId: item._id,
                                      add: true,
                                    })
                                  )
                                }
                              >
                                Add To Cart
                              </Button>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleProductList;
