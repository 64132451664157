import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';
import Reducer from './slices/slices';

const persistConfig = {
    key: 'root',
    storage,
}
const persistedReducer = persistReducer(persistConfig, Reducer)

export const store = configureStore({
    reducer: {
        user: persistedReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: [thunk]
    },
})
export const persistor = persistStore(store)