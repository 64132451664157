import React, { useState, useEffect } from "react";
import "../../assets/css/cart.css";
import Button from "react-bootstrap/Button";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { useSelector, useDispatch } from "react-redux";
import { IMG_URL, dataService } from "../../config/services";
import { productManagement } from "../../redux/slices/service";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.user);
  const [couponCode, setCouponCode] = useState("");
  const [isApplied, setIsApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [freeProduct, setFreeProduct] = useState(null)
  console.log(cart, "maulik19")
  const subTotal = (arr) => {
    return arr?.reduce((c, p) => c + p.qty * p.price, 0);
  };
  const afterDiscount = (discount = 0) => {
    const total = subTotal(
      cart?.products?.map((e) => ({ ...e, price: e?.productId?.price }))
    );
    return total - discount;
  };
  const checkCouponCode = () => {
    dataService
      .get(`cart/check-promo-code?promoCode=${couponCode}`)
      .then(({ data }) => {
        console.log(data, "maulik31")
        const { offerData } = data.data;
        setIsApplied(true);
        setDiscountAmount(data.data.codeData.discount);
        setFreeProduct(offerData ? { ...offerData, isFree: true } : null)
        alert(data.message);
      })
      .catch((e) => {
        setIsApplied(false);
        setDiscountAmount(0);
        setFreeProduct(null)
        alert(e.response.data.message);
      });
  };

  useEffect(() => {
    if (couponCode.length) {
      checkCouponCode();
    }
  }, [cart]);

  return (
    <>
      <div className="cart-main-container">
        <div className="cart-title-main">
          <h1> My Cart </h1>
        </div>
        <div className="table-responsive">
          <Table className="table">
            <thead className="table-head-div">
              <tr>
                <th>Image</th>
                <th>Product</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Remove </th>
              </tr>
            </thead>
            <tbody>
              {(freeProduct ? [...cart?.products, { productId: { ...freeProduct } }] : cart?.products)?.map(({ productId: data, qty }, i, arr) => {
                console.log(arr)
                return (
                  <tr className="table-row-div">
                    <td>
                      <div className="cart-product-image-div">
                        <img
                          height="150"
                          width="200"
                          src={IMG_URL + data?.mainImage}
                          alt=""
                          className="cart-product-image"
                        />
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="cart-product-title">
                        <p>{data?.name}</p>
                      </div>
                    </td>
                    <td>
                      {" "}
                      <div className="cart-price-text">₹ {data?.price}</div>
                    </td>
                    <td>
                      {" "}
                      {
                        !data?.isFree ? <div className="cart-qty-div">
                          <Button
                            onClick={() =>
                              dispatch(
                                productManagement({
                                  productId: data._id,
                                  add: false,
                                })
                              )
                            }
                          >
                            -
                          </Button>
                          <p className="cart-qty-count">{qty}</p>
                          <Button
                            onClick={() =>
                              dispatch(
                                productManagement({
                                  productId: data._id,
                                  add: true,
                                })
                              )
                            }
                          >
                            +
                          </Button>
                        </div> : ""
                      }

                    </td>
                    <td>
                      <div className="cart-price-text">₹ {data?.isFree ? data.price : qty * data?.price}</div>
                    </td>
                    <td>
                      {" "}
                      <div className="cart-remove-product-section">
                        {
                          !data?.isFree ? <MdDelete
                            onClick={() =>
                              dispatch(
                                productManagement({
                                  productId: data._id,
                                  add: false,
                                  remove: true,
                                })
                              )
                            }
                          /> : "Free"
                        }

                      </div>
                    </td>
                  </tr>
                );
              })}

              {/* <tr className="table-row-div">
              <td>
                <div className="cart-product-image-div">
                  <img
                    height="150"
                    width="200"
                    src={Images.product1}
                    alt=""
                    className="cart-product-image"
                  />
                </div>
              </td>
              <td>
                {" "}
                <div className="cart-product-title">
                  <p>Healthy Hair & Nails Super Gummies</p>
                </div>
              </td>
              <td>
                {" "}
                <div className="cart-price-text">₹500</div>
              </td>
              <td>
                {" "}
                <div className="cart-qty-div">
                  <Button variant="secondary">-</Button>
                  <p className="cart-qty-count">1</p>
                  <Button variant="secondary">+</Button>
                </div>
              </td>
              <td>
                <div className="cart-price-text">₹500</div>
              </td>
              <td>
                {" "}
                <div className="cart-remove-product-section">
                  <MdDelete />
                </div>
              </td>
            </tr>
            <tr className="table-row-div">
              <td>
                <div className="cart-product-image-div">
                  <img
                    height="150"
                    width="200"
                    src={Images.product1}
                    alt=""
                    className="cart-product-image"
                  />
                </div>
              </td>
              <td>
                {" "}
                <div className="cart-product-title">
                  <p>Healthy Hair & Nails Super Gummies</p>
                </div>
              </td>
              <td>
                {" "}
                <div className="cart-price-text">₹500</div>
              </td>
              <td>
                {" "}
                <div className="cart-qty-div">
                  <Button variant="secondary">-</Button>
                  <p className="cart-qty-count">1</p>
                  <Button variant="secondary">+</Button>
                </div>
              </td>
              <td>
                <div className="cart-price-text">₹500</div>
              </td>
              <td>
                {" "}
                <div className="cart-remove-product-section">
                  <MdDelete />
                </div>
              </td>
            </tr> */}
            </tbody>
          </Table>
        </div>

        <div className="container-fluid">
          <div className="justify-content-end row">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="cart-summary-section">
                <div className="text-end">
                  <input
                    value={couponCode}
                    onChange={(e) =>
                      setCouponCode(e.target.value?.trim()?.toUpperCase())
                    }
                    id="exampleFormControlInput1"
                    placeholder="Coupon Code"
                  />
                  <button
                    variant="primary"
                    disabled={isApplied}
                    onClick={() => checkCouponCode()}
                  >
                    Apply
                  </button>
                  <button
                    variant="primary"
                    disabled={!isApplied}
                    onClick={() => {
                      setCouponCode("");
                      setIsApplied(false);
                      setDiscountAmount(0);
                    }}
                  >
                    Remove
                  </button>
                </div>
                <div className="cart-summary">Cart Totals</div>
                <div className="cart-summary-sub-total">
                  <div>Sub-total</div>
                  <div>
                    ₹
                    {cart &&
                      cart?.products?.length &&
                      subTotal(
                        cart?.products?.map((e) => ({
                          ...e,
                          price: e?.productId?.price,
                        }))
                      )}
                  </div>
                </div>
                <div className="cart-summary-discount">
                  <div>discount</div>
                  <div>₹{discountAmount}</div>
                </div>
                <div className="cart-summary-total-price">
                  <div>Total Price</div>
                  <div>₹ {afterDiscount(discountAmount)}</div>
                </div>

                <div
                  onClick={() => {
                    navigate("/check-out", {
                      state: {
                        couponCode: isApplied ? couponCode : ""
                      },
                    });
                  }}
                  className="cart-check-out-btn"
                >
                  Check Out
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default Cart;
