import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="main-div">
      <h1>Privacy Policy</h1>

      <section id="general">
        <h2>General</h2>
        <p>
          Welcome to Vittzios{" "}
          <a href="https://vittzios.com/">(www.vittzios.com)</a>, an online
          platform including both a website and a mobile application
          (collectively referred to as the "Website"), operated by Vittzios.{" "}
        </p>
        <p>
          Your access to the Website, use of specific Products and Services (as
          defined in the Terms and Conditions), and provision or access of any
          information through the Website implies your acceptance of our Terms
          and Conditions ("Terms") By doing so, you acknowledge that your
          information will be stored, utilized, and accessed in accordance with
          this Privacy Policy. Any capitalized terms used hereunder shall carry
          the meaning ascribed to them under the Terms of Use.{" "}
        </p>
        <h3>Our Privacy Policy Explained:</h3>
        <p>This Privacy Policy elaborates on the following:</p>
        <ul>
          <li>The nature of information we collect from you.</li>
          <li>How we gather and employ that information.</li>
          <li>
            How you can selectively provide, access, and update your
            information.
          </li>
          <li>How we process, share, and safeguard your information.</li>
        </ul>
        <p>
          This Privacy Policy applies to all Users who access or use our
          Website.
        </p>

        <p>
          By accessing or using our Website, or by providing us with your
          information in any form, you confirm that you have read, understood,
          and agreed to the practices and policies detailed in this Privacy
          Policy, thereby consenting to the collection, use, sharing, and
          disclosure of your information as outlined here.
        </p>
        <p>
          We retain the right to modify, amend, add, or remove portions of this
          Privacy Policy at our sole discretion, at any time. Your continued use
          of the Website after any such amendments will signify your acceptance
          of the Privacy Policy in its revised form. If you access or use the
          Website from a location outside India, you do so at your own risk and
          are solely responsible for adhering to applicable local laws.
        </p>
        <p>
          If you disagree with any part or the entirety of this Privacy Policy,
          you are welcome to communicate your concerns to us via email at{" "}
          <a href="mailto:wholesomenutritech@gmail.com">
            wholesomenutritech@gmail.com
          </a>
          . In such a case, please refrain from using the Website, its
          associated services, or providing any of your information.
        </p>
      </section>

      <section id="information-collection">
        <h2>Information Collection and Means of Collection</h2>
        <p>
          At Vittzios, safeguarding your privacy and the information you entrust
          to us is paramount. This section elucidates the categories of
          information we collect from you and the methods employed to gather it.
          We urge you to carefully review the following details, which expound
          on how your data is acquired, processed, and safeguarded.
        </p>
        <ul>
          <li>
            <span class="highlight">Registration Information:</span> When you
            decide to register on our Website, we collect the essential
            registration details, which comprise your name, email address, and
            phone number. To ensure an added layer of security, we verify your
            phone number through a one-time password sent to your mobile number.
          </li>
          <li>
            <span class="highlight">Order Information:</span> For the seamless
            processing of your orders on our Website, we may requisition
            information including the items you place in your cart, your mailing
            address, and specific payment particulars. Notably, we do not gather
            bank account information, debit/credit card data, or other payment
            specifics.
          </li>
        </ul>
      </section>

      <section id="usage-of-information">
        <h2>Use of Information</h2>
        <p>
          The information you entrust to us serves diverse purposes, all aimed
          at enhancing your experience and ensuring the smooth operation of our
          services. Please take a moment to understand how your data is utilized
          for various functions:
        </p>
        <ul>
          <li>
            <span class="highlight">
              Enhancing User Experience and Business Improvement:
            </span>{" "}
            We employ your information to operate and enhance the Website,
            cultivating a positive user experience and advancing our overall
            business operations.
          </li>
          <li>
            <span class="highlight">Order Processing and Delivery:</span> Your
            information is instrumental in processing and delivering your orders
            efficiently and accurately.
          </li>
          <li>
            <span class="highlight"> Access to Website and Services:</span> We
            utilize your information to grant you access to our Website,
            allowing you to make purchases and benefit from our services.{" "}
          </li>
          <li>
            <span class="highlight"> Quality Assessment and Auditing:</span>{" "}
            Your information contributes to quality assessments and audits aimed
            at maintaining our service standards.{" "}
          </li>
          <li>
            <span class="highlight"> Communication and Updates: </span> We reach
            out to you for various reasons, including order updates, query
            resolutions, consultation details, follow-up consultations, and
            informing you about new products or services.{" "}
          </li>
          <li>
            <span class="highlight"> Addressing Legal Matters: </span> Your data
            might be used to investigate and address situations involving
            potential threats, fraud, violations of our Terms, or legal
            obligations.{" "}
          </li>
          <li>
            <span class="highlight"> Notifications and Alerts: </span> We inform
            you about promotions, alerts, changes to the Website, or future fees
            through SMS, email, and phone calls{" "}
          </li>
          <li>
            <span class="highlight"> Gathering Feedback:</span> We may contact
            you periodically to gather your valuable feedback on existing and
            potential future Products and Services.{" "}
          </li>
        </ul>
      </section>

      <section>
        <h2>Disclosure of Information</h2>
        <p>
          In the interest of transparency and your informed understanding, this
          section outlines how your Personal Information and Personal
          Information or Data (PID) may be disclosed to third parties for
          specific purposes as detailed below:
        </p>
        <ul>
          <li>
            <span class="highlight">Logistics Partners:</span> Your Personal
            Information may be shared with our logistics partners to facilitate
            the processing and delivery of orders placed on the Website.
          </li>
          <li>
            <span class="highlight">Business Transition:</span> In the event of
            a merger, consolidation, acquisition, or substantial asset transfer,
            your Personal Information and PID might be disclosed and transferred
            to a third party involved in such business transitions.
          </li>
          <li>
            <span class="highlight">Enhancing Products and Services:</span> To
            improve our Products and Services, we may transfer Personal
            Information and PID to third parties, even beyond India.
          </li>
          <li>
            <span class="highlight">Updating Personal Information:</span> You
            have the authority to review, correct, update, change, or delete
            your Personal Information associated with Registration Information
            and Order Information on our Website. Kindly communicate your
            requests by writing to us using the contact details provided below.
            You can opt to delete specific portions of your Personal Information
            or request complete deletion, and we will adhere to your requests
            within a reasonable timeframe, unless legal requirements necessitate
            the retention of certain data.
          </li>
          <li>
            <span class="highlight">
              you can contact us at any time by connecting via the support
              email:
            </span>
            <a href="mailto:wholesomenutritech@gmail.com">
              wholesomenutritech@gmail.com
            </a>
            .
          </li>
          <li>
            <span class="highlight">Verification of Identity:</span> To ensure
            the accurate delivery of Products and Services, we reserve the right
            to verify and authenticate your identity along with your Personal
            Information. This step is vital to maintain the security of your
            interactions with us.
          </li>
          <li>
            <span class="highlight">
              Limitations on Access and Modification:
            </span>
            Accessing, correcting, updating, or deleting your Personal
            Information or PID might be denied or restricted if such actions
            would infringe upon the rights of another individual or conflict
            with applicable laws. <br />
            <br />
            Your data and preferences are in your hands. If you have any queries
            about managing your information or need assistance, please reach out
            to us. Your privacy and control remain our priority
          </li>
        </ul>
      </section>
      <section>
        <h2>Security and Retention of Information</h2>
        <p>
          At Vittzios, safeguarding your information is paramount. We are
          committed to employing physical, technical, and procedural safeguards
          to ensure the security of your data against unauthorized access,
          misuse, damage, modification, or disclosure. Our security measures
          include:
        </p>

        <ul>
          <li>
            <span class="highlight">Limited Access:</span> Personal Information
            is restricted to employees and agents who require access for
            processing purposes. These individuals are bound by stringent
            contractual confidentiality obligations, and any breach could result
            in disciplinary actions or termination.
          </li>
        </ul>

        <h2>Usage Restrictions</h2>

        <ol>
          <li>
            <span class="highlight"></span>Your access and utilization of this
            Site are subject to specific limitations to ensure appropriate and
            lawful engagement. By engaging with this Site, you acknowledge and
            agree to adhere to the following usage restrictions:
          </li>
          <li>
            <span class="highlight">Personal and Non-Commercial Usage:</span>{" "}
            The Site is intended solely for your personal, non-commercial use,
            and for obtaining information or references.
          </li>
          <li>
            <span class="highlight">
              Prohibited Reproduction and Distribution:
            </span>
            You are strictly prohibited from reproducing, duplicating, selling,
            reusing, reselling, adapting, publishing, broadcasting, framing,
            posting, uploading, modifying, distributing, or transmitting any
            content from this Site, unless expressly permitted in writing by us.
          </li>
          <li>
            <span class="highlight">
              Conformity with Law and Responsible Conduct:
            </span>
            Transmission of any material that is unlawful, harmful (including
            viruses), threatening, libelous, defamatory, deceptive, fraudulent,
            obscene, scandalous, inflammatory, pornographic, indecent, profane,
            or encourages such conduct violating the law is strictly forbidden.
          </li>
          <li>
            <span class="highlight">Unsolicited Solicitation Restricted:</span>
            You may not post unsolicited or unauthorized promotional materials,
            advertisements, or solicitations, including non-public information
            about any entity without proper authorization.
          </li>
          <li>
            <span class="highlight">No Impersonation:</span> You are expressly
            forbidden from impersonating any individual, entity, or using
            likeness, names, or properties of third parties in a misleading
            manner.
          </li>
          <li>
            <span class="highlight">
              Compliance with Information Technology Act:
            </span>
            Adherence to the Information Technology Act, 2000, and related
            regulations is mandatory. In the event of any "Cyber Security
            Incident" as defined in The Information Technology (The Indian
            Computer Emergency Response Team and Manner of Performing Functions
            and Duties) Rules, 2013, you must promptly inform the Company's
            Grievance Officer.
          </li>
          <li>
            <span class="highlight">Prohibition of Automated Access:</span>{" "}
            Usage of automated devices, programs, algorithms, or similar
            methodologies to access, acquire, copy, or monitor any section of
            the Website or its content is strictly prohibited.
          </li>
          <li>
            <span class="highlight">Unauthorized Access is Prohibited:</span>
            Unauthorized access to the Website, its features, systems, networks,
            or any other service connected to it by means of hacking, password
            "mining," or any other illegitimate techniques is strictly
            prohibited.
          </li>
          <li>
            <span class="highlight">Material Review and Deletion:</span> The
            Company reserves the right to review, edit, or remove materials
            submitted by you or others on this Site. However, it is not
            obligated to do so and assumes no liability arising from such
            materials. The Company retains the authority to terminate your
            access or use of the Site at any time and for any reason, without
            prior notice or liability.
          </li>
          <li>
            <span class="highlight"></span>Adhering to these usage restrictions
            ensures that you use the Site responsibly and within legal bounds.
            Non-compliance may lead to immediate termination of access, without
            prior notice or liability.
          </li>
        </ol>
      </section>
      <section>
        <h2>Changes to the Policy</h2>
        <p>
          Vittzios retains the right to revise, alter, or modify this Privacy
          Policy as needed. Such updates will take effect from the date of the
          respective change. By continuing to access or use the Website
          following any amendments, you are implying your consent to the revised
          Privacy Policy
        </p>
        <p>
          We value your trust and commitment to Vittzios. As we strive to
          enhance our services and offerings, our Privacy Policy may evolve to
          reflect those improvements. Should you have any queries about policy
          changes, please don't hesitate to contact us using the information
          provided below.
        </p>
        <p>
          Your privacy is at the core of our practices, and we're dedicated to
          keeping you informed about any changes that might impact your
          experience with us.
        </p>
      </section>
      <section class="steps">
        <h2>Miscellaneous</h2>

        <ol>
          <li>
            <span class="highlight">Disclaimer:</span> While Vittzios is
            committed to safeguarding your privacy, we cannot guarantee that all
            your Personal Information and PID will never be disclosed in ways
            not specified in this Privacy Policy. Thus, we cannot assure you,
            and you should not expect, that your Information or private
            communications will always remain private. By using the Website, you
            acknowledge and accept that you bear all responsibility and assume
            any risks associated with your use of the Website, the broader
            internet, the information you access or post, and your overall
            conduct on and off the Website.
          </li>
          <li>
            <span class="highlight">Indemnity:</span> You commit to indemnifying
            us against any third-party suits or disputes arising from the
            disclosure of information by you to third parties, either via our
            Website or any other means, as well as from your utilization of
            third-party websites, applications, and resources. We hold no
            liability for actions undertaken by third parties in relation to
            your Personal Information or PID that you may have shared with them.
          </li>
          <li>
            <span class="highlight">Severability:</span> Each clause of this
            Privacy Policy remains distinct and autonomous, separate from and
            independent of other clauses, unless expressly indicated otherwise
            or contextual cues suggest otherwise within the Privacy Policy.
            Should one or more clauses be deemed null and void, it shall not
            affect the validity of the remaining clauses within this Privacy
            Policy.
          </li>
        </ol>

        <p>
          As part of our commitment to transparency and your understanding of
          our practices, these clauses collectively contribute to a
          comprehensive perspective on your interactions with Vittzios. Should
          you have any inquiries or require clarifications, don't hesitate to
          contact us using the information provided below.
        </p>

        <div class="contact-info">
          <strong>Contact Information for Miscellaneous Queries:</strong>
          <p>
            Email:
            <a href="mailto:wholesomenutritech@gmail.com">
              wholesomenutritech@gmail.com
            </a>
          </p>
        </div>
      </section>
      <section class="overview">
        <h2>Overview</h2>

        <p>
          This website (
          <a href="http://www.Vittzios.com" target="_blank">
            www.Vittzios.com
          </a>
          ) and all its contents are owned and operated by Vittzios (hereinafter
          referred to as “Company”, “We” “Our” or “Us” as the case may be).
        </p>

        <p>
          By visiting our site or making a purchase from us, you engage with our
          "Service" and consent to abide by the subsequent terms and conditions
          ("Terms of Service," "Terms"). This includes any additional terms,
          conditions, and policies referred to herein or linked through
          hyperlinks. The Terms of Service apply to all users of the site,
          including, but not limited to, browsers, vendors, customers,
          merchants, and content contributors.
        </p>

        <p>
          Kindly review these Terms of Service carefully before accessing or
          using our website. Upon accessing or using any aspect of the site, you
          affirm your agreement to be bound by these Terms of Service. If you
          disagree with any of the terms and conditions outlined in this
          agreement, please refrain from accessing the website or using its
          services. Should these Terms of Service be deemed an offer, acceptance
          is exclusively contingent on adherence to these Terms of Service.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
