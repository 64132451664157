const Images = {
  headerLogo: require("../assets/image/headerLogo.png"),
  heroSliderImage: require("../assets/image/heroSlider/hero-slider.jpg"),
  heroSliderImage2: require("../assets/image/heroSlider/hero-slider-2.jpg"),
  heroSliderImage3: require("../assets/image/heroSlider/hero-slider-3.jpg"),
  heroSliderImage4: require("../assets/image/heroSlider/hero-slider-4.jpg"),
  heroSliderImageResponsive: require("../assets/image/heroSlider/hero-slider-responsive.jpg"),
  heroSliderImage2Responsive: require("../assets/image/heroSlider/hero-slider2-responsive.jpg"),
  heroSliderImage3Responsive: require("../assets/image/heroSlider/hero-slider3-responsive.jpg"),
  heroSliderImage4Responsive: require("../assets/image/heroSlider/hero-slider4-responsive.jpg"),
  skinCare: require("../assets/image/SkinCare.png"),
  bodyCare: require("../assets/image/BodyCare.png"),
  hairCare: require("../assets/image/HairCare.png"),
  wellness: require("../assets/image/Wellness.png"),
  fragrance: require("../assets/image/Fragrance.png"),
  product: require("../assets/image/product.jpg"),
  product1: require("../assets/image/product1.jpg"),
  product2: require("../assets/image/product2.jpg"),
  product3: require("../assets/image/product3.jpg"),
  skinCareBannerImage : require("../assets/image/product-category-banner.png"),
  skinCareBannerImageResponsive : require("../assets/image/skincare-banner-responsive.jpg"),
  hairCareBannerImage : require("../assets/image/hair-care-banner.jpg"),
  hairCareBannerImageResponsive : require("../assets/image/hair-care-banner-responsive.jpg"),
  crueltyLogo : require("../assets/image/cruelty.png"),
  labTestedLogo : require("../assets/image/lab-tested.png"),
  parabenLogo : require("../assets/image/paraben.png"),
  phthalateLogo : require("../assets/image/phthalate.png"),
  sulphateLogo : require("../assets/image/sulphate.png"),
  toxicLogo : require("../assets/image/toxic.png"),
  veganLogo : require("../assets/image/vegan.png"),
  wellNessBannerImage : require("../assets/image/wellness-banner.png"),
  wellNessBannerImageResponsive : require("../assets/image/wellness-banner-responsive.jpg"),
  bodyCareBannerImage : require("../assets/image/body-care-banner.jpg"),
  bodyCareBannerImageResponsive : require("../assets/image/body-care-banner-responsive.jpg"),
  fragranceBannerImage : require("../assets/image/fragrance-banner.jpg"),
  fragranceBannerImageResponsive : require("../assets/image/fragrance-banner-responsive.jpg"),
  footerLogo : require("../assets/image/footer-logo.png"),
  singleProductLogo : require("../assets/image/Benefits.png"),
  hairGummise : require("../assets/image/hair-gummise.png"),
  hairGummise2 : require("../assets/image/hair-gummise-2.png"),
  hairGummise3 : require("../assets/image/hair-gummise-3.png"),
  hairGummise4 : require("../assets/image/hair-gummise-4.png"),
  hairGummise5 : require("../assets/image/hair-gummise-5.png"),
  skinCareDetail : require("../assets/image/skin-care-detail.png"),
  nonHabitForming : require("../assets/image/non-habit-forming.png"),
  delicious : require("../assets/image/delicious.png"),
  hundredPrVegan : require("../assets/image/hundred-pr-vegan.png"),
  gelatinFree : require("../assets/image/gelatin-free.png"),
  sugarFree : require("../assets/image/sugar-free.png"),
  gmoFree : require("../assets/image/gmo-free.png"),
  useThisFor : require("../assets/image/use-this-for.jpg"),
  howToUse : require("../assets/image/how-to-use.jpg"),
  reduceFineLines : require("../assets/image/reduce-fine-lines.png"),
  hydratesSkin : require("../assets/image/hydrates-skin.png"),
  skinRepair : require("../assets/image/skin-repair.png"),
  packedAntioxidants : require("../assets/image/packed-antioxidants.png"),
  singleProductHair : require("../assets/image/single-product-hair.jpg"),
};

export default Images;
