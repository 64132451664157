import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Images from "../../utils/Images";
import { dataService, IMG_URL } from "../../config/services";

const Slider = () => {
  const [allSliderList, setAllSliderList] = useState([]);
  const getAllSlider = () => {
    dataService.get("user/all-sliders").then(({ data }) => {
      setAllSliderList(data.data);
    });
  };
  useEffect(() => {
    getAllSlider();
  }, []);
  return (
    <Carousel>
      {allSliderList.map((data) => {
        return (
          <Carousel.Item>
            <img
              className="hero-slider-desktop-image"
              src={IMG_URL + data.image}
              alt=""
            />
            <img
              className="hero-slider-mobile-image"
              src={Images.heroSliderImageResponsive}
              alt=""
            />
          </Carousel.Item>
        );
      })}
      {/* <Carousel.Item>
        <img
          className="hero-slider-desktop-image"
          src={Images.heroSliderImage2}
          alt=""
        />
        <img
          className="hero-slider-mobile-image"
          src={Images.heroSliderImage2Responsive}
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="hero-slider-desktop-image"
          src={Images.heroSliderImage3}
          alt=""
        />
        <img
          className="hero-slider-mobile-image"
          src={Images.heroSliderImage3Responsive}
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="hero-slider-desktop-image"
          src={Images.heroSliderImage4}
          alt=""
        />
        <img
          className="hero-slider-mobile-image"
          src={Images.heroSliderImage4Responsive}
          alt=""
        />
      </Carousel.Item> */}
    </Carousel>
  );
};

export default Slider;
