import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    // .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "This is not a valid format")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),

  // .oneOf([Yup.ref("email"), ""], "Email must match"),

  password: Yup.string()
    // .required("Password is required")
    // .min(8, "Must contain 8 characters"),
    .required("Please enter your password"),
  // .min(8, "Password must be at least 8 characters")
  // .max(16, "Password must be at most 16 characters")
  // .matches( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."),
});

export const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your firstName"),
  lastName: Yup.string().required("Please enter your lastName"),
  email: Yup.string().required("Please enter your email"),
  password: Yup.string().required("Please enter your password"),
});

export const checkOutSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your firstName"),
  lastName: Yup.string().required("Please enter your lastName"),
  address: Yup.string().required("Please enter your email"),
  pincode: Yup.string().required("Please enter your password"),
  state: Yup.string().required("Please enter your password"),
  country: Yup.string().required("Please enter your password"),
  email: Yup.string().required("Please enter your password"),
  phone: Yup.string().required("Please enter your password"),
});

export const checkOutSchemaWithS = checkOutSchema.shape({
  sFirstName: Yup.string().required("Please enter your firstName"),
  sLastName: Yup.string().required("Please enter your lastName"),
  sAddress: Yup.string().required("Please enter your email"),
  sPincode: Yup.string().required("Please enter your password"),
  sState: Yup.string().required("Please enter your password"),
  sCountry: Yup.string().required("Please enter your password"),
  sEmail: Yup.string().required("Please enter your password"),
  sPhone: Yup.string().required("Please enter your password"),
});