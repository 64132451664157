import React from "react";
import "../../assets/css/Login.css";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { LoginSchema } from "../../validation/schema";
import { dataService } from "../../config/services"
import { useDispatch } from "react-redux";
import { logIn } from "../../redux/slices/slices";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const submitForm = (data) => {
    dataService.post('user/login', data).then(({ data }) => {
      dispatch(logIn(data.data))
      localStorage.setItem("userToken", data.data)
      navigate("/");
    }).catch((e) => {
      console.log(e)
    })
  }
  return (
    <div className="login-main-div">
      <div className="login-box-div">
        <p className="login-text"> Sign In To Your Acccount </p>
        <Formik
          validationSchema={LoginSchema}
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={submitForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isValid,
            dirty,
            touched,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit}>
              <div class="login-text-field-div">
                <label className="login-form-lable" for="exampleFormControlInput1" class="form-label">
                  Email
                </label>
                <input
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                />
              </div>
              <div class="login-text-field-div">
                <label className="login-form-lable" for="exampleFormControlInput1" class="form-label">
                  Password
                </label>
                <input
                  type="text"
                  onBlur={handleBlur}
                  value={values.password}
                  onChange={handleChange}
                  class="form-control"
                  name="password"
                  id="exampleFormControlInput1"
                />
              </div>
              <Button className="login-button" type="submit" variant="primary">
                Login
              </Button>
            </form>
          )}
        </Formik>
        <Link className="login-forgot-text">Forgot Your Password?</Link>
        <div className="login-new-customer-section">
          <p className="login-new-customer-text"> New customer? </p>
          <Link to={"/sign-up"} className="login-new-create-acc-text">Create an account</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
